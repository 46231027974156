import React from "react"
import { graphql, StaticQuery } from "gatsby"
import "../sass/main.scss"
import { Layout, SEO, ImageSection, HeadSection } from "../components"
import { Row, Col, Container } from "react-bootstrap"
import $ from "jquery"
interface AIStrategyPageState {
  images: any[]
  selectedImages: string[]
  width: number
  classes: string[]
}
export default class AIStrategyPage extends React.Component<
  any,
  AIStrategyPageState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      images: [],
      selectedImages: [],
      width: 0,
      classes: [
        "gallery__side-item--first",
        "gallery__side-item--second",
        "gallery__side-item--third",
        "gallery__side-item--last",
      ],
    }
  }
  resizeTimer: NodeJS.Timeout
  componentDidMount() {
    this.updateSelectedImages()
    window.addEventListener("resize", this.updateSelectedImages)
  }
  headTitle = (): string => {
    return "AI Strategy"
  }
  headSubTitle = (): string => {
    return "Executive AI education and strategy"
  }
  headDescription = (): string => {
    return `Organisations are increasingly sceptical of AI's impact and confused about what to do about it. We work with leadership teams to cut through the noise, developing AI strategies that align with their objectives. Then we advise on the right technologies, partners and talent they'll need to execute.`
  }
  updateSelectedImages = () => {
    clearTimeout(this.resizeTimer)
    clearTimeout(this.setSelectedImageTimeOut)
    clearTimeout(this.setStartGalleryImageTimeOut)
    $(".gallery img").removeClass("fade-out")
    let vm = this
    this.resizeTimer = setTimeout(function() {
      let images: string[] = []
      if (window.innerWidth >= 1366) {
        images = [
          "morgan stanley",
          "gsk",
          "ey",
          "unilever",
          "bbc",
          "cbre",
          "google",
          "itv",
          "dfs",
          "dhl",
          "barclays",
          "tesco",
          "accenture",
          "experian",
          "gsk",
          "home office",
        ]
        vm.setState({ selectedImages: images }, () =>
          vm.startGalleryAnimation(),
        )
      } else if (window.innerWidth >= 1024) {
        images = [
          "morgan stanley",
          "gsk",
          "ey",
          "unilever",
          "bbc",
          "cbre",
          "google",
          "itv",
          "dfs",
          "dhl",
          "accenture",
          "experian",
          "gsk",
          "home office",
        ]
        vm.setState({ selectedImages: images }, () =>
          vm.startGalleryAnimation(),
        )
      } else if (window.innerWidth >= 768) {
        images = [
          "dfs",
          "odeon",
          "techdata",
          "prezzo",
          "ds-smith",
          "gigaclear",
          "tesco",
          "pwc",
          "accenture",
          "experian",
          "gsk",
          "home office",
        ]
        vm.setState({ selectedImages: images }, () =>
          vm.startGalleryAnimation(),
        )
      } else {
        images = [
          "morgan stanley",
          "gsk",
          "ey",
          "unilever",
          "bbc",
          "cbre",
          "accenture",
          "experian",
          "gsk",
          "home office",
        ]
        vm.setState({ selectedImages: images }, () =>
          vm.startGalleryAnimation(),
        )
      }
    }, 250)
  }
  imageDescription = () => {
    return (
      <Col
        xs="3"
        sm="7"
        md="8"
        lg="9"
        className="z-index-2 mb-150 offset-lg-1 offset-xl-3"
      >
        <div className="st-block st-block--transparent st-block--no-padding">
          <h3>
            We'll come and do our talk for free to a group of your choosing, big
            or small, online or in-person.{" "}
            <span className="font-weight-medium">
              We've done hundreds over the years, and we promise you won't
              regret it.
            </span>
          </h3>
        </div>
      </Col>
    )
  }

  imageContent = () => {
    return (
      <Row>
        <Col xs="3" sm="8" md="10" lg="12" xl="12">
          <p className="mb-32">
            It will get you and your team on the same page about AI, and spark
            some thought as to how it could be used in your business. After the
            talk, we will discuss some of your key challenges, and think about
            how we could work together.
          </p>
          <a
            href="https://www.youtube.com/channel/UCD4Faf51k8jcsrmNLvXfHXg"
            className="learn-more"
            target="_blank"
            rel="nofollow noreferrer"
          >
            Watch our talks
          </a>
        </Col>
      </Row>
    )
  }
  setSelectedImageTimeOut: NodeJS.Timeout = null
  setStartGalleryImageTimeOut: NodeJS.Timeout = null
  startGalleryAnimation = () => {
    let vm = this
    if (this.state.images.length < 1) {
      return
    }
    let selectedImageIndex = Math.floor(
      Math.random() * this.state.selectedImages.length,
    )
    let nextImagesData = this.state.images.filter((image) => {
      return !this.state.selectedImages.includes(image.node.name)
    })
    let nextImageIndex = Math.floor(Math.random() * nextImagesData.length)
    let nextImageName = nextImagesData[nextImageIndex].node.name

    let CURRENT_IMAGE = $(`img[data-index="${selectedImageIndex}"]`)
    CURRENT_IMAGE.removeClass("fade-in").addClass("fade-out")

    let cacheImages = [...this.state.selectedImages] // copying the old datas array
    cacheImages[selectedImageIndex] = nextImageName
    this.setSelectedImageTimeOut = setTimeout(function() {
      vm.setState({
        selectedImages: cacheImages,
      })
    }, 1000)

    this.setStartGalleryImageTimeOut = setTimeout(function() {
      CURRENT_IMAGE = $(`img[data-index="${selectedImageIndex}"]`)
      CURRENT_IMAGE.removeClass("fade-out").addClass("fade-in")
    }, 2000)
    this.setStartGalleryImageTimeOut = setTimeout(function() {
      vm.startGalleryAnimation()
    }, 3000)
  }
  render() {
    return (
      <Layout step={2}>
        {this.state.images.length < 1 ? (
          <StaticQuery
            query={graphql`
              query {
                images: allFile(
                  filter: { relativeDirectory: { eq: "ai-strategies" } }
                ) {
                  edges {
                    node {
                      id
                      publicURL
                      name
                    }
                  }
                }
              }
            `}
            render={(data) => {
              this.setState({ images: data.images.edges })
              return null
            }}
          />
        ) : null}
        <SEO
          title="Satalia Services | Executive AI Education and Strategy"
          description="Satalia helps organisations develop AI strategies that align with objectives, advising on the technologies, partners and talent they'll need to execute."
          twitterTitle="Satalia Services | Executive AI Education and Strategy"
          twitterDescription="Satalia develops enterpise AI strategies, advising on the technologies, partners and talent needed to execute."
          openGraphTitle="Satalia Services | Executive AI Education and Strategy"
          openGraphDescription="We develop actionable enterprise AI strategies."
        />
        <Container>
          <section id="ai-strategy" className="mb-25">
            <HeadSection
              title={this.headTitle()}
              subtitle={this.headSubTitle()}
              description={this.headDescription()}
            ></HeadSection>
          </section>
          <section id="build-technology" className="mb-40">
            <Row>
              <Col
                xs="3"
                sm="8"
                md="8"
                lg="6"
                xl="6"
                className="offset-lg-2 offset-xl-2"
              >
                <div className="st-block st-block--transparent">
                  <h3>
                    We’ve worked with the following brands to cut through the
                    noise,{" "}
                    <span className="font-weight-medium">
                      developing AI strategies that align with their objectives.
                    </span>
                  </h3>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
        <Container fluid className="p-0 position-relative gallery mb-285">
          {this.state.selectedImages
            ? this.state.selectedImages
                .slice(this.state.selectedImages.length - 4)
                .map((image, index): any => {
                  return (
                    <div
                      key={this.state.selectedImages.length - 4 + index}
                      className={`gallery__side-item ${this.state.classes[index]}`}
                    >
                      <div className="st-block gallery__item">
                        <img
                          alt={image}
                          src={
                            this.state.images.find(
                              (i): any => i.node.name === image,
                            )?.node.publicURL
                          }
                          data-index={
                            this.state.selectedImages.length - 4 + index
                          }
                        />
                      </div>
                    </div>
                  )
                })
            : null}
          <Container>
            <Row>
              {this.state.selectedImages
                ? this.state.selectedImages
                    .slice(0, this.state.selectedImages.length - 4)
                    .map((image, index): any => {
                      return (
                        <Col xs="1" sm="2" md="2" lg="2" xl="2" key={index}>
                          <div className="st-block gallery__item">
                            <img
                              alt={image}
                              src={
                                this.state.images.find(
                                  (i): any => i.node.name === image,
                                )?.node.publicURL
                              }
                              data-index={index}
                            />
                          </div>
                        </Col>
                      )
                    })
                : null}
            </Row>
          </Container>
        </Container>
        <Container>
          <section id="engagement-process" className="mb-285">
            <Row className="justify-content-lg-end">
              <Col
                xs="3"
                sm="8"
                md="9"
                lg="9"
                xl="9"
                className="offset-xl-4 offset-lg-4 offset-md-0"
              >
                <h2>Engagement process</h2>
              </Col>
              <Col xs="3" sm="4" md="3" lg="3" xl="3" className="mb-45">
                <div className="st-block">
                  <p className="font-weight-medium">
                    01.
                    <br />
                    Educate
                  </p>
                  <p>
                    Through company-wide keynotes, and workshops with key
                    stakeholders, we get everyone on the same page about AI,
                    what it is and how it could be applied to your business.
                  </p>
                </div>
              </Col>
              <Col xs="3" sm="4" md="3" lg="3" xl="3" className="mb-45">
                <div className="st-block">
                  <p className="font-weight-medium">
                    02.
                    <br />
                    Explore
                  </p>
                  <p>
                    We meet with teams across your organisation to understand
                    their challenges and identify where technology could help.
                    We prioritise these opportunities with senior management to
                    ensure they align with strategic objectives.
                  </p>
                </div>
              </Col>
              <Col xs="3" sm="4" md="3" lg="3" xl="3" className="mb-45">
                <div className="st-block">
                  <p className="font-weight-medium">
                    03.
                    <br />
                    Report
                  </p>
                  <p>
                    We produce a report which outlines your AI strategy,
                    advising on the technology, procurement, talent,
                    partnerships and other key initiatives you’ll need to
                    deliver. We meet with you to discuss the details, and plan
                    any future engagements.
                  </p>
                </div>
              </Col>
            </Row>
          </section>
          <section id="out-talk" className="pb-75">
            <ImageSection
              description={this.imageDescription()}
              content={this.imageContent()}
              image="satalia-ai-strategy"
              align="end"
              aos="fade-right"
            ></ImageSection>
          </section>
        </Container>
      </Layout>
    )
  }
}
